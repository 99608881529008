import React, {useEffect} from "react";
import {TRANSITION_DURATION, WIDGET_TYPE_POSTER, WIDGET_TYPE_GRID, WIDGET_TYPE_GRID_GROUP} from "./contanst";
import Carousel from "react-multi-carousel";
import FrameworkTitle from "./components/FrameworkTitle/FrameworkTitle";
import PlaylistItem from "./components/PlaylistItem/PlaylistItem";
import "@detail/Pages/DetailPage/CarrouselStyle.css";
import "react-multi-carousel/lib/styles.css";
import style from "./style.module.css";
import { onlyOne, poster_v2, responsive_v2 } from "../../../../detail/src/Pages/DetailPage/CarrouselStyle";
import './carousel.css';
import GroupItemComponent from "./components/GroupItemComponent/GroupItemComponent";

const DefaultWidgetComponent_V2 = ({
  id,
  backgroundColor,
  backgroundImage,
  titleColor,
  titulo,
  hasMoreContents,
  playlist,
  typeBanner,
  access,
  event,
}) => {

  return (
    <>
      <div className={style.playlist} style={style}>
        <FrameworkTitle
          id={id}
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          hasMoreContents={hasMoreContents}
          titleColor={titleColor}
          titulo={titulo}
        />
        {playlist?.length &&
          (typeBanner === WIDGET_TYPE_GRID || typeBanner === WIDGET_TYPE_GRID_GROUP ? (
            <div className={style.gridWidget}>
              {playlist?.map((item) => (
                <PlaylistItem key={item.id} item={item} access={access} event={event} typeBanner={typeBanner} />
              ))}
            </div>
          ) : (
            <div id="carousel_v2">
              <Carousel
                showDots={false}
                removeArrowOnDeviceType={["mobile", "xsmobile", "xlmobile"]}
                responsive={typeBanner === WIDGET_TYPE_POSTER ? poster_v2 : playlist.length === 1 ? onlyOne : responsive_v2}
                autoPlay={false}
                transitionDuration={TRANSITION_DURATION}
                customTransition="transform 500ms ease-in-out 0s"
                containerClass={style.carousel}
              >
                {playlist?.map((item) => (
                  <PlaylistItem key={item.id} item={item} access={access} event={event} typeBanner={typeBanner} />))}
              </Carousel>
            </div>
          ))}
      </div>
    </>
  );
};

export default DefaultWidgetComponent_V2;
