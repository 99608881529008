import React, {useContext, useEffect} from "react";
import { NavLink } from "react-router-dom";
import "../../../Menu.css";
import { useTranslation } from "react-i18next";
import { User } from "../../../../../../../user/src/Context/User";
import { checkMenuActive } from "../../../../../Utils/utils";
import { useSelector } from "react-redux";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../../Resources/Multitenant/tenantConstants";


const LateralMenu = ({
  onContainerClick,
  displayMenu,
  sections,
  changeSection,
  studioSection,
  configurationSection,
  logOutSection,
  logout,
  handleToggleNotificationMenu,
  notifications,
  changeDynamicSection,
  getIconSection,
  configurationIcon,
  logoutIcon,
}) => {
  const { t } = useTranslation();
  const { userName } = useContext(User);

  const sectionSelected = useSelector((state) => state.commonState.sectionSelected);


  const handleRedirectToStudio = () => {
    window.open(studioSection.url, "_self");
  };

  return (
    <div className="MenuLateralResContainer" onClick={onContainerClick} >
      <div className="MenuLateralRes ">
        <div className={"MenuSection"}>
          <div className={"PecharMenu"} onClick={displayMenu}>
            <ion-icon name="close" />
          </div>
        </div>
        <div className={"MenuContainer"}>
          <div>
            {sections &&
              sections.map((a, index) => (
                <div key={a.section.name + "-" + index}>
                  <NavLink
                    to={{
                      pathname: a.url,
                      aboutProps: {
                        dinamic: a.section.screenId,
                      },
                    }}
                    onClick={() =>
                      a.section.screenId !== null ? changeDynamicSection(a.section) : changeSection(a.section)
                    }
                    className={"MenuSectionRes " + a.section.iconId}
                    key={a.section.iconId}
                    isActive={(match, location) =>
                      checkMenuActive(match, location, a, sectionSelected, sections, sections)
                    }
                  >
                    {getIconSection(a.section.iconId)}
                    <span className="TextRes">{a.section.name}</span>
                  </NavLink>
                </div>
              ))}
          </div>
          <div>
            {notifications.length > 0 && (
              <a onClick={() => handleToggleNotificationMenu()} className={"MenuSectionRes"}>
                <ion-icon name={"notifications"} />
                <span className="TextRes">{t("notifications")}</span>
                <div className={"NotificationsLateralContainer"}>
                  <p className={"NotificationsLateralNumber"}>{notifications.length}</p>
                </div>
              </a>
            )}
            {studioSection.url && !!userName && (
              <a onClick={handleRedirectToStudio} className={"MenuSectionRes " + studioSection.section.iconId}>
                <ion-icon name={studioSection.section.iconId} />
                <span className="TextRes">{studioSection.section.name}</span>
              </a>
            )}
            {!!userName && !!localStorage.getItem("auth") && (
              <>
                <NavLink
                  to={{ pathname: configurationSection.url }}
                  onClick={() => changeSection(configurationSection.section)}
                  className={"MenuSectionRes " + configurationSection.section.iconId}
                  key={configurationSection.section.iconId}
                >
                  {configurationIcon}
                  <span className="TextRes">{t(configurationSection.section.name)}</span>
                </NavLink>
                <a onClick={logout} className={"MenuSectionRes " + logOutSection.section.iconId} target={"blank"}>
                  {logoutIcon}
                  <span className={"TextRes " + MULTITENANT_CONSTANTS[TENANT].menuClassLogout}>
                    {logOutSection.section.name}
                  </span>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};

export default LateralMenu;
