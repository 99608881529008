import React, {useEffect} from "react";
import ActionButtons from "../ActionButtons/ActionButtons";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../../../app/src/Resources/Multitenant/tenantConstants";
import MetaData from "../MetaData/MetaData";
import MediaCover from "../MediaCover/MediaCover";
import Rating from "../Rating/Rating";
import {URL_CONTENT} from "../../../Routes/Location";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {actionTypeContainer} from "../../../../Utils/utils";

function GroupItemComponent({ item, access, event,typeBanner, id }) {

    const {t} = useTranslation();

    const getURLSection = () => {
       /*return URL_CONTENT + "/widget/" + id*/
        return actionTypeContainer(access).ROUTE + "/" + id;
    }

    return (
        <section key={item.id} className={style.playlistItemContainer}>
            <MediaCover data={item} typeBanner={typeBanner} event={event} access={access} />
            <section
                className={`${style.playlistItemFooter} ${MULTITENANT_CONSTANTS[TENANT].ImageWithText
                    ? style.playlistItemFooter__topText
                    : style.playlistItemFooter__bottomText}`}
            >
                    <Link to={getURLSection}
                          style={{padding: "0px 0px 20px 0px", display: "flex", width: "120px"}}
                          className="Titulo button-link-secondary">
                        <div className="ShowMoreText">{t('ficha.showmore')}</div>
                        <FontAwesomeIcon icon={faChevronRight} style={{margin: "4px 0 0 5px"}}/>
                    </Link>
            </section>
        </section>
    );
}

export default GroupItemComponent;

GroupItemComponent.propTypes = {
    playlist: PropTypes.array,
};
