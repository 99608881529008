import {TENANT,MULTITENANT_CONSTANTS} from "../Resources/Multitenant/tenantConstants";
import {URL_BASE, URL_SERGIO, URL_SGA} from "./servicesConstants";

export const getWidgetsServices = (sectionSelected,callback) => {
    fetch(`${URL_BASE}${URL_SERGIO}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/screens/${sectionSelected}?fields=*.*`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            return response.json();
        })
        .then(data => {
            callback(data);
            /*setResponseSectionData(data);
            getBannersWidget(data.widgets[0]?.widgets_id?.id);*/
            /*creatObjectSectionToPrint(data)*/
            // Hacer algo con los datos recibidos
        })
        .catch(error => {
            console.error('Error:', error);
            // Manejar el error de la solicitud
        });
}

export const getBannersWidgetServices = (id, callback) => {
    fetch(`${URL_BASE}${URL_SGA}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/widgets/${id}/content?filter[status]=published`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            return response.json();
        })
        .then(data => {
            callback(data.data);
            // Hacer algo con los datos recibidos
        })
        .catch(error => {
            console.error('Error:', error);
            // Manejar el error de la solicitud
        });
}

export const getMenu = (callback) => {

    fetch(`${URL_BASE}${URL_SERGIO}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/interfaces`)
        .then(response => response.json())
        .then(data => {
            callback(data.data[0].menu);
        })
        .catch(error => {
            console.error('Error:', error);
        });
}